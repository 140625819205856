import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../components/Signin';

const SigninPage = () => {
  return (
    <div>
      <ScrollToTop /> 
      <SignIn />
    </div>
  );
};

export default SigninPage;
